import React, { useState, useEffect } from "react"
import { Router } from "@reach/router"
import { Link } from "gatsby"

import styled from "styled-components"
import tw from "twin.macro"

import Layout from "../components/layout"
import ProjectCard from "../components/ProjectCard"
// import SEO from "../components/seo";
import ProjectPage from "./projects"
import projects from "../../projects.json"
import octocat from "../images/Octocat.png"

const StyledProjectCard = styled(ProjectCard)`
  width: 100px;
  ${tw`md:w-1/3 transform hover:scale-110 duration-200 ease-in-out`};
`

const Banner = styled.div`
  border: 3px solid white;
  margin: 1rem;
  h1 {
    color: #1d1d1d;
    color: white;
  }
  p {
    color: #1d1d1d;
    color: white;
  }
  a {
    color: #1d1d1d;
    color: white;
  }
  ${tw`p-8 md:m-0`};
`
const Corner = styled.div`
  width: 20vw;
  height: 20vw;
  background-color: #1d1d1d;
  position: relative;
  top: 0;
  right: 0;
  /* clip-path: circle(100px at 300px 0px); */
`

const StyledLink = styled.div`
  margin-right: 0.5rem;
  ${tw`transform hover:scale-110 duration-200 ease-in-out`};
  a:hover {
    color: yellow
  }
`

const IndexPage = () => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (!loaded) {
      setLoaded(true)
    }
  })
  return (
    <>
      {loaded && (
        <>
          <Layout>
            {/* <SEO title="Hello world!" /> */}
            <div id="screen-welcome" className="darkblue">
              {/* <Corner>
          <img style={{zIndex: 9999, left: 0, bottom: 0, }} src={octocat} width="100px" height="auto" alt="Octocat"/>
        </Corner> */}
              {/* <Parallax
                y={[-130, 130]}
                // tagOuter="figure"
                css={{ position: "relative", zIndex: 1 }}
              > */}
              <Banner className="title-box font">
                <h1 className="font">Hi, my name is Markus! 👋</h1>
                <p style={{ margin: 0, maxWidth: "22rem" }}>
                  I'm a full stack web developer, who loves to solve problems
                  and to learn new things.
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <p style={{ margin: 0 }}>
                      <StyledLink>
                        <Link to="/about/">About me</Link>
                      </StyledLink>
                    </p>
                    <p style={{ margin: 0, marginRight: "1rem" }}>🧑🏻‍💻</p>

                    <p style={{ margin: 0 }}>
                      <StyledLink>
                        <Link to="/contact/">Contact</Link>
                      </StyledLink>
                    </p>
                    <p>☕</p>
                  </div>
                </p>
              </Banner>
              {/* </Parallax> */}
            </div>

            <div
              id="screen-tech"
              className="black"
              css={{ position: "relative", zIndex: 2 }}
            >
              <h1
                tw="mr-auto mt-12"
                css={{
                  paddingLeft: "20vw",
                  zIndex: 1000,
                  position: "relative",
                  color: "white",
                }}
              >
                WORK
              </h1>

              {projects.map(project => {
                return <StyledProjectCard project={project} />
              })}
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#1d1d1d"
                fill-opacity="1"
                d="M0,64L120,90.7C240,117,480,171,720,213.3C960,256,1200,288,1320,304L1440,320L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
              ></path>
            </svg>
            <div id="screen-quote">
              <h1 id="quote" style={{ color: "#1d1d1d" }}>
                “You should name a variable using the same care with which you
                name a first-born child.”
              </h1>
              <h1 style={{ color: "#1d1d1d" }} id="author">
                - Robert C. Martin
              </h1>
            </div>
            <svg
              id="sticky-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
            >
              <path
                fill="#1d1d1d"
                fill-opacity="1"
                d="M0,320L120,314.7C240,309,480,299,720,256C960,213,1200,139,1320,101.3L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
              ></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 310">
              <path
                fill="#FDFD98"
                fill-opacity="1"
                d="M0,64L120,101.3C240,139,480,213,720,240C960,267,1200,245,1320,234.7L1440,224L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
              ></path>
            </svg>
            <div id="screen" className="yellow">
              <p style={{ color: "#1d1d1d", margin: 0, padding: "1rem" }}>
                ©{new Date().getFullYear()} Markus Köhler Web Developer
              </p>
            </div>
            <Router>
              <ProjectPage path="/projects/:id" state={{ title: "space" }} />
            </Router>
          </Layout>
        </>
      )}
    </>
  )
}

export default IndexPage
