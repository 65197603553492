import { Link } from "gatsby"
import React from "react"
import quiz from "../images/quiz.gif"
import fa from "../images/fa.png"
import space from "../images/space.gif"
import folienhain from "../images/folienhain.gif"
import martha from "../images/martha.gif"
import marian from "../images/marian.gif"
import ramboll from "../images/ramboll-card.png"
import lovelace from "../images/lovelace-card.png"
import dbt from "../images/dbt.png"

import styled from "styled-components"
import tw from "twin.macro"

import Fade from "react-reveal/Fade"

const images = {
  quiz: quiz,
  fa: fa,
  space: space,
  folienhain: folienhain,
  martha: martha,
  marian: marian,
  ramboll: ramboll,
  lovelace: lovelace,
  dbt: dbt,
}
const StyledBox = styled.div`
  ${tw`md:(transform hover:scale-105 duration-200 ease-in-out)`};
`

const DoubleCard = styled.div`
  margin: 20px;
  width: 50vw;
  @media only screen and (max-width: 600px) {
    width: 90vw;
  }
  ${tw`lg:(flex ml-auto mr-auto) ml-2 mr-2`}
  p {
    ${tw`lg:(ml-auto mr-auto) ml-2 mr-2`}
    font-size: 32;
    padding: 20;
    margin-top: "auto";
    margin-bottom: "auto";
    text-align: center;
    color: black;
    padding: 20px;
  }
  h6 {
    /* ${tw`p-1`} */
    padding: 20px;
    margin: 0;
  }
`
const TextSide = styled.div`
  @media only screen and (max-width: 600px) {
    height: 12rem;
  }
  color: #1d1d1d;
  /* width: 25vw; */
  padding: 20px;
  ${tw`lg:(flex ml-auto mr-auto h-auto w-1/2) flex justify-center items-center `};
`

const ImageSide = styled.div`
  /* width: 25vw; */
  background-color: tomato;
  ${tw`lg:(w-1/2)`};
`

const ProjectCard = props => {
  const project = props.project
  return (
    <StyledBox>
      <Fade right>
        <Link to={`/projects/${project.short}`}>
          <DoubleCard>
            <TextSide
              // className="project-card-left"
              style={{
                backgroundColor: project.color,
                display: "flex",
                position: "relative",
                flexDirection: "column",
              }}
            >
              <h6
                style={{
                  textAlign: "left",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                {project.name}
              </h6>
              <h6
                style={{
                  textAlign: "left",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                }}
              >
                {project.type}
              </h6>
              <p>{project.description}</p>
            </TextSide>
            <ImageSide>
              <img
                src={images[project.short]}
                alt="Otter dancing with a fish"
              />
            </ImageSide>
          </DoubleCard>
        </Link>
      </Fade>
    </StyledBox>
  )
}

export default ProjectCard
